import './playlist.css';
import {needsLogin, PrimaryInfo, SecondaryInfo, spotifyAuth} from "../util/util";
import {useEffect} from "react";

export const Playlist = () => {
    useEffect(() => {
        sessionStorage.setItem('route', 'playlist');
        if (needsLogin()) spotifyAuth();
    }, []);

    return (
        <div>
            <PrimaryInfo text={'🚧Playlist Builder🚧'}/>
            <SecondaryInfo text={'Page coming soon...'}/>
        </div>
    );
}