import spotifyIcon from './spotify-icon.png';
import {addCommaToNumber} from './stats';
import React from 'react';
import {TopItem} from "./types";

// Default values for the dropdowns (must be in the array specified in the props)
const DEFAULT_TRACK_COUNT_LIMIT = 100;
const DEFAULT_ARTIST_COUNT_LIMIT = 50;
const DEFAULT_ALBUM_COUNT_LIMIT = 50;

const OPEN_SPOTIFY = 'https://open.spotify.com';

export interface TopTableStyleProps {
    defaultCount: number;
    ddValues: number[];
    tableStyle: string;
    head_horiz: React.ReactElement;
    head_vert: React.ReactElement;
    row_horiz: (item: TopItem) => React.ReactElement;
    row_vert: (item: TopItem) => React.ReactElement;
}

export const trackCountProps: TopTableStyleProps = {
    defaultCount: DEFAULT_TRACK_COUNT_LIMIT,
    ddValues: [25, 50, 100, 250],
    tableStyle: 'table-all',
    head_horiz: (
        <thead>
        <tr className={'table-column-names'}>
            <th style={{width: '5%'}}></th>
            <th style={{width: '5%'}}>#</th>
            <th style={{width: '5%'}}></th>
            <th style={{width: '40%'}}>Track</th>
            <th style={{width: '40%'}}>Artist</th>
            <th style={{textAlign: 'right', width: '5%'}}>Listens</th>
        </tr>
        </thead>
    ),
    head_vert: (
        <thead>
        <tr className={'table-column-names'}>
            <th style={{}}></th>
            <th style={{}}>#</th>
            <th style={{}}></th>
            <th style={{}}>Track</th>
            <th style={{textAlign: 'right'}}>Listens</th>
        </tr>
        </thead>
    ),
    row_horiz: (item: TopItem) => {
        return (
            <tr className={'table-row'} key={item.trackId}>
                <td><a href={OPEN_SPOTIFY + '/track/' + item.trackId} target={'_blank'} rel={'noreferrer'}><img src={spotifyIcon} alt={'Spotify'} style={{width: '1.5rem'}}/></a></td>
                <td>{item.rank}</td>
                <td><img src={item.image} style={{width: '3rem'}} alt={'Unavailable'}/></td>
                <td><a href={OPEN_SPOTIFY + '/track/' + item.trackId} target={'_blank'} rel={'noreferrer'} className={'table-link'}>{item.track}</a></td>
                <td><LinkedArtistList nameString={item.artist} idString={item.artistId}/></td>
                <td style={{textAlign: 'right'}}>{addCommaToNumber(item.count)}</td>
            </tr>
        );
    },
    row_vert: (item: TopItem) => {
        return (
            <tr className={'table-row'} key={item.trackId}>
                <td><a href={OPEN_SPOTIFY + '/track/' + item.trackId} target={'_blank'} rel={'noreferrer'}><img src={spotifyIcon} alt={'Unavailable'} style={{width: '1.5rem'}}/></a></td>
                <td>{item.rank}</td>
                <td><img src={item.image} style={{width: '3rem'}} alt={'Unavailable'}/></td>
                <td>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <a href={OPEN_SPOTIFY + '/track/' + item.trackId} target={'_blank'} rel={'noreferrer'} className={'table-link'}><b>{item.track}</b></a>
                        <LinkedArtistList nameString={item.artist} idString={item.artistId}/>
                    </div>
                </td>
                <td style={{textAlign: 'right'}}>{addCommaToNumber(item.count)}</td>
            </tr>
        );
    }
}
export const trackTimeProps = {
    defaultCount: DEFAULT_TRACK_COUNT_LIMIT,
    ddValues: [25, 50, 100, 250],
    tableStyle: 'table-all',
    head_horiz: (
        <thead>
        <tr className={'table-column-names'}>
            <th style={{width: '5%'}}></th>
            <th style={{width: '5%'}}>#</th>
            <th style={{width: '5%'}}></th>
            <th style={{width: '40%'}}>Track</th>
            <th style={{width: '40%'}}>Artist</th>
            <th style={{textAlign: 'right', width: '5%'}}>Minutes</th>
        </tr>
        </thead>
    ),
    head_vert: (
        <thead>
        <tr className={'table-column-names'}>
            <th style={{}}></th>
            <th style={{}}>#</th>
            <th style={{}}></th>
            <th style={{}}>Track</th>
            <th style={{textAlign: 'right'}}>Minutes</th>
        </tr>
        </thead>
    ),
    row_horiz: (item: TopItem) => {
        return (
            <tr className={'table-row'} key={item.trackId}>
                <td><a href={OPEN_SPOTIFY + '/track/' + item.trackId} target={'_blank'} rel={'noreferrer'}><img src={spotifyIcon} alt={'Unavailable'} style={{width: '1.5rem'}}/></a></td>
                <td>{item.rank}</td>
                <td><img src={item.image} style={{width: '3rem'}} alt={'Unavailable'}/></td>
                <td><a href={OPEN_SPOTIFY + '/track/' + item.trackId} target={'_blank'} rel={'noreferrer'} className={'table-link'}>{item.track}</a></td>
                <td><LinkedArtistList nameString={item.artist} idString={item.artistId}/></td>
                <td style={{textAlign: 'right'}}>{addCommaToNumber(Math.round(item.count/60))}</td>
            </tr>
        );
    },
    row_vert: (item: TopItem) => {
        return (
            <tr className={'table-row'} key={item.trackId}>
                <td><a href={OPEN_SPOTIFY + '/track/' + item.trackId} target={'_blank'} rel={'noreferrer'}><img src={spotifyIcon} alt={'Unavailable'} style={{width: '1.5rem'}}/></a></td>
                <td>{item.rank}</td>
                <td><img src={item.image} style={{width: '3rem'}} alt={'Unavailable'}/></td>
                <td>
                    <div>
                        <a href={OPEN_SPOTIFY + '/track/' + item.trackId} target={'_blank'} rel={'noreferrer'} className={'table-link'}>{item.track}</a>
                        <LinkedArtistList nameString={item.artist} idString={item.artistId}/>
                    </div>
                </td>
                <td style={{textAlign: 'right'}}>{addCommaToNumber(Math.round(item.count/60))}</td>
            </tr>
        );
    }
}
export const artistCountProps = {
    defaultCount: DEFAULT_ARTIST_COUNT_LIMIT,
    ddValues: [10, 25, 50, 100],
    tableStyle: 'table-all table-all-artist',
    head_horiz: (
        <thead>
        <tr className={'table-column-names'}>
            <th style={{width: '5%'}}></th>
            <th style={{width: '15%'}}>#</th>
            <th style={{width: '75%'}}>Artist</th>
            <th style={{textAlign: 'right', width: '5%'}}>Listens</th>
        </tr>
        </thead>
    ),
    head_vert: (
        <thead>
        <tr className={'table-column-names'}>
            <th style={{width: '5%'}}></th>
            <th style={{width: '15%'}}>#</th>
            <th style={{width: '75%'}}>Artist</th>
            <th style={{textAlign: 'right', width: '5%'}}>Listens</th>
        </tr>
        </thead>
    ),
    row_horiz: (item: TopItem) => {
        return (
            <tr className={'table-row'} key={item.artistId}>
                <td><a href={OPEN_SPOTIFY + '/artist/' + item.artistId} target={'_blank'} rel={'noreferrer'}><img src={spotifyIcon} alt={'Unavailable'} style={{width: '1.5rem'}}/></a></td>
                <td>{item.rank}</td>
                <td><a href={OPEN_SPOTIFY + '/artist/' + item.artistId} target={'_blank'} rel={'noreferrer'} className={'table-link'}>{item.artist}</a></td>
                <td style={{textAlign: 'right'}}>{addCommaToNumber(item.count)}</td>
            </tr>
        );
    },
    row_vert: (item: TopItem) => {
        return (
            <tr className={'table-row'} key={item.artistId}>
                <td><a href={OPEN_SPOTIFY + '/artist/' + item.artistId} target={'_blank'} rel={'noreferrer'}><img src={spotifyIcon} alt={'Unavailable'} style={{width: '1.5rem'}}/></a></td>
                <td>{item.rank}</td>
                <td><a href={OPEN_SPOTIFY + '/artist/' + item.artistId} target={'_blank'} rel={'noreferrer'} className={'table-link'}>{item.artist}</a></td>
                <td style={{textAlign: 'right'}}>{addCommaToNumber(item.count)}</td>
            </tr>
        );
    }
}
export const artistTimeProps = {
    defaultCount: DEFAULT_ARTIST_COUNT_LIMIT,
    ddValues: [10, 25, 50, 100],
    tableStyle: 'table-all table-all-artist',
    head_horiz: (
        <thead>
        <tr className={'table-column-names'}>
            <th style={{width: '5%'}}></th>
            <th style={{width: '15%'}}>#</th>
            <th style={{width: '75%'}}>Artist</th>
            <th style={{textAlign: 'right', width: '5%'}}>Minutes</th>
        </tr>
        </thead>
    ),
    head_vert: (
        <thead>
        <tr className={'table-column-names'}>
            <th style={{width: '5%'}}></th>
            <th style={{width: '15%'}}>#</th>
            <th style={{width: '75%'}}>Artist</th>
            <th style={{textAlign: 'right', width: '5%'}}>Minutes</th>
        </tr>
        </thead>
    ),
    row_horiz: (item: TopItem) => {
        return (
            <tr className={'table-row'} key={item.artistId}>
                <td><a href={OPEN_SPOTIFY + '/artist/' + item.artistId} target={'_blank'} rel={'noreferrer'}><img src={spotifyIcon} alt={'Unavailable'} style={{width: '1.5rem'}}/></a></td>
                <td>{item.rank}</td>
                <td><a href={OPEN_SPOTIFY + '/artist/' + item.artistId} target={'_blank'} rel={'noreferrer'} className={'table-link'}>{item.artist}</a></td>
                <td style={{textAlign: 'right'}}>{addCommaToNumber(Math.round(item.count/60))}</td>
            </tr>
        );
    },
    row_vert: (item: TopItem) => {
        return (
            <tr className={'table-row'} key={item.artistId}>
                <td><a href={OPEN_SPOTIFY + '/artist/' + item.artistId} target={'_blank'} rel={'noreferrer'}><img src={spotifyIcon} alt={'Unavailable'} style={{width: '1.5rem'}}/></a></td>
                <td>{item.rank}</td>
                <td><a href={OPEN_SPOTIFY + '/artist/' + item.artistId} target={'_blank'} rel={'noreferrer'} className={'table-link'}>{item.artist}</a></td>
                <td style={{textAlign: 'right'}}>{addCommaToNumber(Math.round(item.count/60))}</td>
            </tr>
        );
    }
}
export const albumCountProps = {
    defaultCount: DEFAULT_ALBUM_COUNT_LIMIT,
    ddValues: [10, 25, 50, 100],
    tableStyle: 'table-all',
    head_horiz: (
        <thead>
        <tr className={'table-column-names'}>
            <th style={{width: '5%'}}></th>
            <th style={{width: '5%'}}>#</th>
            <th style={{width: '5%'}}></th>
            <th style={{width: '40%'}}>Album</th>
            <th style={{width: '40%'}}>Artist</th>
            <th style={{textAlign: 'right', width: '5%'}}>Listens</th>
        </tr>
        </thead>
    ),
    head_vert: (
        <thead>
        <tr className={'table-column-names'}>
            <th style={{}}></th>
            <th style={{}}>#</th>
            <th style={{}}></th>
            <th style={{}}>Album</th>
            <th style={{textAlign: 'right'}}>Listens</th>
        </tr>
        </thead>
    ),
    row_horiz: (item: TopItem) => {
        return (
            <tr className={'table-row'} key={item.albumId}>
                <td><a href={OPEN_SPOTIFY + '/album/' + item.albumId} target={'_blank'} rel={'noreferrer'}><img src={spotifyIcon} alt={'Unavailable'} style={{width: '1.5rem'}}/></a></td>
                <td>{item.rank}</td>
                <td><img src={item.image} style={{width: '3rem'}} alt={'Unavailable'}/></td>
                <td><a href={OPEN_SPOTIFY + '/album/' + item.albumId} target={'_blank'} rel={'noreferrer'} className={'table-link'}>{item.album}</a></td>
                <td><LinkedArtistList nameString={item.artist} idString={item.artistId}/></td>
                <td style={{textAlign: 'right'}}>{addCommaToNumber(item.count)}</td>
            </tr>
        );
    },
    row_vert: (item: TopItem) => {
        return (
            <tr className={'table-row'} key={item.albumId}>
                <td><a href={OPEN_SPOTIFY + '/album/' + item.albumId} target={'_blank'} rel={'noreferrer'}><img src={spotifyIcon} alt={'Unavailable'} style={{width: '1.5rem'}}/></a></td>
                <td>{item.rank}</td>
                <td><img src={item.image} style={{width: '3rem'}} alt={'Unavailable'}/></td>
                <td>
                    <div>
                        <a href={OPEN_SPOTIFY + '/album/' + item.albumId} target={'_blank'} rel={'noreferrer'} className={'table-link'}>{item.album}</a>
                        <LinkedArtistList nameString={item.artist} idString={item.artistId}/>
                    </div>
                </td>
                <td style={{textAlign: 'right'}}>{addCommaToNumber(item.count)}</td>
            </tr>
        );
    }
}
export const albumTimeProps = {
    defaultCount: DEFAULT_ALBUM_COUNT_LIMIT,
    ddValues: [10, 25, 50, 100],
    tableStyle: 'table-all',
    head_horiz: (
        <thead>
        <tr className={'table-column-names'}>
            <th style={{width: '5%'}}></th>
            <th style={{width: '5%'}}>#</th>
            <th style={{width: '5%'}}></th>
            <th style={{width: '40%'}}>Album</th>
            <th style={{width: '40%'}}>Artist</th>
            <th style={{textAlign: 'right', width: '5%'}}>Minutes</th>
        </tr>
        </thead>
    ),
    head_vert: (
        <thead>
        <tr className={'table-column-names'}>
            <th style={{}}></th>
            <th style={{}}>#</th>
            <th style={{}}></th>
            <th style={{}}>Album</th>
            <th style={{textAlign: 'right'}}>Minutes</th>
        </tr>
        </thead>
    ),
    row_horiz: (item: TopItem) => {
        return (
            <tr className={'table-row'} key={item.albumId}>
                <td><a href={OPEN_SPOTIFY + '/album/' + item.albumId} target={'_blank'} rel={'noreferrer'}><img src={spotifyIcon} alt={'Unavailable'} style={{width: '1.5rem'}}/></a></td>
                <td>{item.rank}</td>
                <td><img src={item.image} style={{width: '3rem'}} alt={'Unavailable'}/></td>
                <td><a href={OPEN_SPOTIFY + '/album/' + item.albumId} target={'_blank'} rel={'noreferrer'} className={'table-link'}>{item.album}</a></td>
                <td><LinkedArtistList nameString={item.artist} idString={item.artistId}/></td>
                <td style={{textAlign: 'right'}}>{addCommaToNumber(Math.round(item.count/60))}</td>
            </tr>
        );
    },
    row_vert: (item: TopItem) => {
        return (
            <tr className={'table-row'} key={item.albumId}>
                <td><a href={OPEN_SPOTIFY + '/album/' + item.albumId} target={'_blank'} rel={'noreferrer'}><img src={spotifyIcon} alt={'Unavailable'} style={{width: '1.5rem'}}/></a></td>
                <td>{item.rank}</td>
                <td><img src={item.image} style={{width: '3rem'}} alt={'Unavailable'}/></td>
                <td>
                    <div>
                        <a href={OPEN_SPOTIFY + '/album/' + item.albumId} target={'_blank'} rel={'noreferrer'} className={'table-link'}>{item.album}</a>
                        <LinkedArtistList nameString={item.artist} idString={item.artistId}/>
                    </div>
                </td>
                <td style={{textAlign: 'right'}}>{addCommaToNumber(Math.round(item.count/60))}</td>
            </tr>
        );
    }
}

interface LinkedArtistListProps {
    nameString: string,
    idString: string,
}

const LinkedArtistList = (props: LinkedArtistListProps) => {
    if (props === undefined || props.nameString === undefined || props.idString === undefined) {
        return null;
    }
    const names = props.nameString.split(';;');
    const ids = props.idString.split(';;');

    return (
        <div>
            {names.map((name, index) => (
                <React.Fragment key={index}>
                    {index !== 0 && ', '}
                    <a key={index} href={`https://open.spotify.com/artist/${ids[index]}`} target={'_blank'} rel={'noreferrer'} className={'table-link'}>
                        {name}
                    </a>
                </React.Fragment>
            ))}
        </div>
    );
}