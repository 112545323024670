import {Chart} from 'react-google-charts';
import React from 'react';
import {AveragePopularity, ModeYear} from "./types";

interface AllChartsProps {
    totalMinutes: string,
    averageYear: number,
    averageLength: string,
    medianYear: number,
    totalTracks: string,
    uniqueAlbums: string,
    uniqueArtists: string,
    uniqueTracks: string,
    decadeBreakdown: string[][],
    weekDayBreakdown: string[][],
    hourBreakdown: number[],
    averagePopularity: AveragePopularity[],
    modeYear: ModeYear[],
    percentExplicit: string,
}

interface BasicPanelProps {
    primary: string,
    data: any,
    last: boolean | null,
    commentary: string,
}

interface GoogleChartProps {
    data: any,
}

export const AllCharts = (props: AllChartsProps) => {
    return (
        <>
            <div className={'all-panels'}>
                <BasicPanel primary={'Total Minutes'} data={props.totalMinutes} commentary={getCommentary(props.totalMinutes, 'totalMinutes', props.totalMinutes)} last={false}/>
                <BasicPanel primary={'Average Year'} data={props.averageYear} commentary={getCommentary(props.totalMinutes, 'averageYear', props.averageYear)} last={false}/>
                <BasicPanel primary={'Average Track Length'} data={props.averageLength} commentary={getCommentary(props.totalMinutes, 'averageLength', props.averageLength)} last={false}/>
                <BasicPanel primary={'Median Year'} data={props.medianYear} commentary={getCommentary(props.totalMinutes, 'medianYear', props.medianYear)} last={false}/>
                {/*<BasicPanel primary={'Percent Explicit'} data={props.percentExplicit} commentary={getCommentary(props.totalMinutes, 'percentExplicit', props.percentExplicit)} last={false}/>*/}
                <BasicPanel primary={'Total Tracks'} data={props.totalTracks} commentary={getCommentary(props.totalMinutes, 'totalTracks', props.totalTracks)} last={false}/>
                <BasicPanel primary={'Unique Album Count'} data={props.uniqueAlbums} commentary={getCommentary(props.totalMinutes, 'uniqueAlbums', props.uniqueAlbums)} last={false}/>
                <BasicPanel primary={'Unique Artist Count'} data={props.uniqueArtists} commentary={getCommentary(props.totalMinutes, 'uniqueArtists', props.uniqueArtists)} last={false}/>
                <BasicPanel primary={'Unique Track Count'} data={props.uniqueTracks} commentary={getCommentary(props.totalMinutes, 'uniqueTracks', props.uniqueTracks)} last={false}/>
                <BasicPanel primary={'Breakdown by Decade'} data={<DecadePieChart data={props.decadeBreakdown}/>} commentary={getCommentary(props.totalMinutes, 'decadeBreakdown', props.decadeBreakdown)} last={false}/>
                <BasicPanel primary={'Breakdown by Day'} data={<DecadePieChart data={props.weekDayBreakdown}/>} last={false} commentary={''}/>
                <BasicPanel primary={'Breakdown by Hour'} data={<HourChart data={props.hourBreakdown}/>} last={true} commentary={''}/>
            </div>
            <div className={'disclaimer'}>
                Disclaimer: Spotify does not provide time zone data, so breakdowns by hour and day are based on Mountain Standard Time (UTC -7:00).
            </div>
        </>
    );
}

const DecadePieChart = (props: GoogleChartProps) => {
    return (
        <div className={'decade-wrapper'}>
            <Chart
                chartType={'PieChart'}
                data={props.data}
                options={{
                    backgroundColor: 'transparent',
                    fontColor: '#cce2e6',
                    legend: {
                        position: 'left',
                        alignment: 'center',
                        textStyle: {
                            color: '#cce2e6'
                        }
                    },
                    chartArea: {
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: '100%',
                    },
                    enableInteractivity: false,
                }}
            />
        </div>
    );
}

const HourChart = (props: GoogleChartProps) => {
    return (
        <div className={'hour-wrapper'}>
            <Chart
                chartType={'BarChart'}
                data={convertHoursToChartData(props.data)}
                options={{
                    backgroundColor: 'transparent',
                    fontColor: '#cce2e6',
                    enableInteractivity: false,
                    orientation: 'horizontal',
                    hAxis: {
                        title: 'Hour',
                        titleTextStyle: {
                            color: '#cce2e6',
                        },
                        textStyle: {
                            color: '#cce2e6',
                        }
                    },
                    vAxis: {
                        title: 'Count',
                        titleTextStyle: {
                            color: '#cce2e6',
                        },
                        textStyle: {
                            color: '#cce2e6',
                        },
                        viewWindow: {
                            min: 0,
                            max: findMax(props.data),
                        }
                    },
                    chartArea: {
                        left: 50, // adjust the left margin to make space for the y-axis labels
                        top: 20, // adjust the top margin to make space for the x-axis labels
                        width: '100%', // adjust the width to make the chart larger
                        height: '60%' // adjust the height to make the chart larger
                    }
                }}
            />
        </div>
    );
}

const BasicPanel = (props: BasicPanelProps) => {
    let style = 'panel';
    if (props.last) {
        style += ' panel-last';
    }

    return (
        <div className={style}>
            <div className={'panel-primary'}>{props.primary}</div>
            <div className={'panel-right'}>
                <div className={'panel-data'}>{props.data}</div>
                {/*<div className={'panel-commentary'}>{props.commentary}</div>*/}
            </div>
        </div>
    );
}

// HELPER METHODS
const getCommentary = (minutes: string, metric: string, data: any) => {
    switch (metric) {
        case 'totalMinutes':
            return 'Rookie numbers.';
        case 'averageYear':
            return 'That was a good year.';
        case 'averageLength':
            return 'That\'s not very long.';
        case 'medianYear':
            return 'That was a better year.';
        case 'percentExplicit':
            return 'That\'s too high.';
        case 'totalTracks':
            return 'Looks like you spend too much time on Spotify.';
        case 'uniqueAlbums':
            return 'Wow, not a whole lot of diversity there.';
        case 'uniqueArtists':
            return 'Nice!';
        case 'uniqueTracks':
            return 'That\'s pretty ok.';
        case 'decadeBreakdown':
            return 'Looks like you need more diversity.';
        case 'hourBreakdown':
            return 'You\'re a night owl.';
        default:
            return 'That\'s pretty ok.';
    }
}

const convertHoursToChartData = (data: number[]): any[] => {
    let result = [];
    result.push(['Hour', 'Count']);
    let hours = ['12AM', '1AM', '2AM', '3AM', '4AM', '5AM', '6AM', '7AM', '8AM', '9AM', '10AM', '11AM', '12PM', '1PM', '2PM', '3PM', '4PM', '5PM', '6PM', '7PM', '8PM', '9PM', '10PM', '11PM']
    let i = 0;
    data.forEach(item => {
        result.push([hours[i], item]);
        i++;
    });
    return result;
}

const findMax = (data: number[]) => {
    let max = 0;
    data.forEach(item => {
        if (item > max) max = item;
    });
    return max;
}