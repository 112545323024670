import './social.css';
import {needsLogin, PrimaryInfo, SecondaryInfo, spotifyAuth} from '../util/util';
import {useEffect} from 'react';

export const Social = () => {
    useEffect(() => {
        sessionStorage.setItem('route', 'social');
        if (needsLogin()) spotifyAuth();
    }, []);

    return (
        <div>
            <PrimaryInfo text={'🚧Share with Friends🚧'}/>
            <SecondaryInfo text={'Page coming soon...'}/>
        </div>
    );
}