import './util.css';
import {Link} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {websocket} from "../index";
import logo from './logo.png'
import screenshot from './esh_screenshot.png'

// GLOBAL CONSTANTS ----------------------------------------------------------------------------------------------------

export const DOMAIN = window.location.hostname;
export const BASE_URL_WEB = window.location.protocol + '//' + DOMAIN + (window.location.port === '' ? '' : ':' + window.location.port);
export const BACKEND_URL = 'https://musicmetrics.app'
const HTTP_METHODS = new Map<string, string>([
    ['login', 'POST'],
    ['stats', 'GET'],
    ['data', 'POST'],
]);

// PROPS TYPES
interface NavButtonProps {
    url: string,
    text: string,
}

export interface InfoProps {
    text: string | React.ReactElement,
}

interface ExtendedStreamingInfoProps {
    callback: () => void,
}

// ELEMENTS COMMON TO EVERY PAGE ---------------------------------------------------------------------------------------

export const Header = () => {
    const [navButtons, setNavButtons] = useState<React.ReactElement>();

    const NavButton = (props: NavButtonProps) => {
        return (
            <Link to={props.url} className={'custom-link'}>
                <div className={'nav-button'}>
                    {props.text}
                </div>
            </Link>
        );
    }

    const HamburgerMenu = () => {
        const [isOpen, setIsOpen] = useState(false);

        const toggle = () => {
            setIsOpen(!isOpen);
        }

        useEffect(() => {
            document.addEventListener('click', (event: MouseEvent) => {
                const target = event.target as HTMLElement;
                if (!target.classList.toString().includes('hamburger')) {
                    setIsOpen(false);
                }
            });
        }, []);

        return (
            <div className={'hamburger'}>
                <div className={'hamburger-button'} onClick={toggle}>
                    <div className={'hamburger-button-line'}/>
                    <div className={'hamburger-button-line'}/>
                    <div className={'hamburger-button-line'}/>
                </div>
                {isOpen && (
                    <div className={'hamburger-menu'}>
                        <ul>
                            <Link to={'/'} className={'custom-link'}><li onClick={toggle}>HOME</li></Link>
                            <Link to={'/stats'} className={'custom-link'}><li onClick={toggle}>STATS</li></Link>
                            <Link to={'/account'} className={'custom-link'}><li onClick={toggle}>ACCOUNT</li></Link>
                        </ul>
                    </div>
                )}
            </div>
        );
    }

    // Dynamically change the navigation bar based on the orientation of the device.
    useEffect(() => {
        // The default navigation bar in landscape mode
        const navButtonsLandscape = (
            <div className={'nav-buttons default-text-color'}>
                <NavButton url={'/'} text={'HOME'}/>
                <NavButton url={'/stats'} text={'STATS'}/>
                <NavButton url={'/account'} text={'ACCOUNT'}/>
            </div>
        );
        // The navigation bar in portrait mode, defined below.
        const navButtonsPortrait: React.ReactElement = (
            <HamburgerMenu/>
        );
        const mediaQuery = window.matchMedia('(orientation: portrait)');
        setNavButtons(mediaQuery.matches ? navButtonsPortrait : navButtonsLandscape);

        const handleOrientationChange = (event: MediaQueryListEvent) => {
            setNavButtons(event.matches ? navButtonsPortrait : navButtonsLandscape);
        }

        mediaQuery.addEventListener('change', handleOrientationChange);
        return () => {
            mediaQuery.removeEventListener('change', handleOrientationChange);
        }
    }, []);

    return (
        <header className={'header-all'}>
            <div>
                <Link to={'/'}>
                    <img
                        className={'logo-primary'}
                        src={logo}
                        alt={'Main logo'}
                    />
                </Link>
            </div>
            {navButtons}
        </header>
    );
}

export const Footer = () => {
    const onClickTemp = () => {
        websocket.send('hello there from the client');
    }

    return (
        <footer className={'footer default-text-color'}>
            <p>
                <span onClick={() => clearStorage()}>&copy;</span> (pending) <span onClick={() => logStorage()}>2023</span> Noah <span onClick={() => onClickTemp()}>Pratt</span> <span className={'text-color-white'}>&#8226;</span>
                <Link to={'/privacy'} className={'custom-link'}> Privacy Policy</Link> <span className={'text-color-white'}>&#8226;</span>
                <Link to={'/terms'} className={'custom-link'}> Terms of Service</Link> <span className={'text-color-white'}>&#8226;</span>
                <Link to={'/about'} className={'custom-link'}> About</Link> <span className={'text-color-white'}>&#8226;</span>
                <Link to={'/contact'} className={'custom-link'}> Contact Us</Link>
            </p>
        </footer>
    );
}

export const PrimaryInfo = (props: InfoProps) => {
    return (
        <div className={'primary-info'}>
            <b>{props.text}</b>
        </div>
    );
}

export const SecondaryInfo = (props: InfoProps) => {
    return (
        <div className={'secondary-info'}>
            {props.text}
        </div>
    );
}

export const ExtendedStreamingInfo = (props: ExtendedStreamingInfoProps) => {
    return (
        <div className={'popup-container'}>
            <div className={'popup-content'}>
                <div style={{width: '100%'}}>
                    <h1>Want All-Time Stats?</h1>
                    <div>To obtain your extended streaming history:<br/><br/>
                        <div style={{textAlign: 'left'}}>
                            <div style={{marginBottom: '.5rem'}}><b>1.</b> Visit your <a href={'https://www.spotify.com/us/account/privacy/'} target={'_blank'} rel={'noreferrer'} className={'custom-link'}><u>Privacy Settings</u></a> on Spotify.<br/></div>
                            <div style={{marginBottom: '.5rem'}}><b>2.</b> Uncheck 'Account data' and select 'Extended streaming history' so that it looks like the screenshot below.<br/></div>
                            <div style={{marginBottom: '.5rem'}}><b>3.</b> Within 30 days, you will receive a .zip file via email (download it).<br/></div>
                            <div style={{marginBottom: '.5rem'}}><b>4.</b> Upload the .zip file here.<br/></div>
                            <div><b>5.</b> Stats from your complete history will be available within a few minutes.<br/></div>
                        </div>
                    </div>
                    <img src={screenshot} alt={'An instructive screenshot'} className={'screenshot'}/>
                </div>
                <div className={'login-button popup-ok'} onClick={props.callback}><b>OK</b></div>
            </div>
        </div>
    );
}

// LOGIN ELEMENTS ------------------------------------------------------------------------------------------------------

export const getMMToken = () => {
    return localStorage.getItem('mm-token');
}

export const getSPToken = () => {
    return localStorage.getItem('sp-token');
}

// USEFUL METHODS ------------------------------------------------------------------------------------------------------

export const fetchInit = (endpoint: string, requestBody: BodyInit | null, token: string) => {
    if (HTTP_METHODS.get(endpoint) === 'GET') {
        return {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    } else {
        return {
            method: HTTP_METHODS.get(endpoint),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: requestBody
        }
    }
}

export const getSpotifyAuthURL = (implicit: boolean) => {
    let client_id = '8b99139c99794d4b9e89b8367b0ac3f4';
    let redirect_uri = BASE_URL_WEB + '/spotify-landing';
    let state = Math.floor(Math.random() * 100000000); // random 8 digit number
    sessionStorage.setItem('state', state.toString());
    let responseType = implicit ? 'token' : 'code';
    let scope = 'user-read-playback-state ' +
        'playlist-read-private ' +
        'playlist-read-collaborative ' +
        'playlist-modify-private ' +
        'playlist-modify-public ' +
        'user-follow-read ' +
        'user-read-currently-playing ' +
        'user-read-playback-position ' +
        'user-read-email ' +
        'user-top-read ' +
        'user-read-recently-played ' +
        'user-read-private ' +
        'user-library-read';

    let show_dialog = 'false';
    let url = 'https://accounts.spotify.com/authorize';
    url += '?response_type=' + encodeURIComponent(responseType);
    url += '&client_id=' + encodeURIComponent(client_id);
    url += '&scope=' + encodeURIComponent(scope);
    url += '&show_dialog=' + encodeURIComponent(show_dialog);
    url += '&redirect_uri=' + encodeURIComponent(redirect_uri);
    url += '&state=' + encodeURIComponent(state);

    return url;
}

export const spotifyAuth = () => {
    spotifyImplicitGrant();
}

const spotifyImplicitGrant = () => {
    window.location.href = getSpotifyAuthURL(true);
}

export const spotifyFullAuth = () => {
    window.location.href = getSpotifyAuthURL(false);
}

export const needsLogin = () => {
    return localStorage.getItem('mm-token') === undefined || !validSpToken();
}

const validSpToken = () => {
    const exp = localStorage.getItem('sp-exp');
    return exp !== undefined && Number(exp) > Date.now();
}

export const clearStorage = () => {
    localStorage.clear();
    sessionStorage.clear();
}

const logStorage = () => {
    console.log(localStorage);
    console.log(sessionStorage);
}
