import './contact.css'
import {PrimaryInfo, SecondaryInfo} from "../util/util";

export const Contact = () => {
    return (
        <div>
            <PrimaryInfo text={'Contact Us'}/>
            <SecondaryInfo text={'Email: musicmetricsapp@gmail.com'}/>
            <SecondaryInfo text={<div>Instagram: <a className={'custom-link'} target={'_blank'} rel={'noreferrer'} href={'https://instagram.com/_musicmetrics'}>@_musicmetrics</a></div>}/>
        </div>
    );
}
