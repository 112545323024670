import './account.css';
import {
    BACKEND_URL,
    clearStorage,
    ExtendedStreamingInfo,
    fetchInit,
    getMMToken,
    needsLogin,
    PrimaryInfo, spotifyAuth
} from "../util/util";
import React, {useEffect, useState} from "react";
import {FileRejection, useDropzone} from "react-dropzone";
import {Link} from "react-router-dom";
import JSZip from "jszip";

const maxFileSize = 30000000
const maxJsonFiles = 15;
const maxZipFiles = 1;

export const Account = () => {
    useEffect(() => {
        sessionStorage.setItem('route', 'account');
        if (needsLogin()) spotifyAuth();
    }, []);

    return (
        <div>
            <PrimaryInfo text={'Account Information'}/>
            <div className={'table-acct'}>
                <div className={'table-row-acct'}>
                    <div>Username</div>
                    <div>{truncateStr(localStorage.getItem('username')!)}</div>
                </div>
                <div className={'table-row-acct'}>
                    <div>Display Name</div>
                    <div>{truncateStr(localStorage.getItem('display_name')!)}</div>
                </div>
                <div className={'table-row-acct'}>
                    <div>Email</div>
                    <div>{localStorage.getItem('email')}</div>
                </div>
                <div className={'table-row-acct'}>
                    <div>Account Created</div>
                    <div>{unixMillisToString(localStorage.getItem('timestamp')!)}</div>
                </div>
                <div className={'table-row-acct'}>
                    <Link to={'/'} className={'custom-link'} onClick={() => clearStorage()}>Logout</Link>
                    <div></div>
                </div>
            </div>
            <Dropzone/>
        </div>
    );
}

const Dropzone = () => {
    const [zipFiles, setZipFiles] = useState<File[]>([]); // for now, there should only be one .zip file
    const [jsonFiles, setJsonFiles] = useState<File[]>([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [hoveredIndex, setHoveredIndex] = useState<number>(-1);
    const [popupVisible, setPopupVisible] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');

    const onDrop = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        setLoadingMessage('Loading...');
        setErrorMessage('');
        setHoveredIndex(-1);

        let numZips = 0;
        let numJson = 0;
        acceptedFiles.forEach((file: File) => {
            if (file.type.includes('zip')) numZips++;
            if (file.type.includes('json')) numJson++;
        });

        if (acceptedFiles?.length) {
            if (zipFiles.length + numZips > maxZipFiles || jsonFiles.length + numJson > maxJsonFiles) {
                setLoadingMessage('');
                setErrorMessage('Too many files');
                return;
            }
            setErrorMessage('');
        }
        if (rejectedFiles?.length) {
            setLoadingMessage('');
            setErrorMessage('Only .zip files under 30MB are accepted');
            return;
        }

        const newZipFiles: File[] = [];
        const newJsonFiles: File[] = [];
        const promises: Promise<any>[] = [];
        acceptedFiles.forEach(file => {
            if (file.type.includes('json')) {
                const reader = new FileReader();
                const promise = new Promise((resolve, reject) => {
                    reader.onload = (event) => {
                        if (isFormattedCorrectly(String(event.target!.result))) {
                            newJsonFiles.push(file);
                            resolve(file);
                        } else reject();
                    }
                    reader.readAsText(file);
                }).then();
                promises.push(promise);
            } else newZipFiles.push(file);
        })

        Promise.all(promises).then(() => {
            setLoadingMessage('');
            const uniqueZipFiles = newZipFiles.filter(file => !zipFiles.some(f => f.name === file.name));
            const uniqueJsonFiles = newJsonFiles.filter(file => !jsonFiles.some(f => f.name === file.name));
            setZipFiles(previousFiles => [...previousFiles, ...uniqueZipFiles]);
            setJsonFiles(previousFiles => [...previousFiles, ...uniqueJsonFiles]);
        }).catch(() => {
            setLoadingMessage('');
            setErrorMessage('One or more of the files you uploaded are not formatted correctly. Make sure they are the correct files and try again.');
        });

    }

    const removeItem = (item: File) => {
        if (errorMessage === 'Too many files') setErrorMessage('');
        setZipFiles(zipFiles.filter(file => file.name !== item.name));
        setJsonFiles(jsonFiles.filter(file => file.name !== item.name));
    }

    const postJsonToServer = (data: string, resolve: (value: unknown) => void, reject: (reason?: any) => void) => {
        fetch(BACKEND_URL + '/api/data/' + localStorage.getItem('username'), fetchInit('data', data, getMMToken()!))
            .then(response => response.json())
            .then(data => resolve(data))
            .catch(error => reject(error));
    }

    const submit = () => {
        setLoadingMessage('Loading...');
        setErrorMessage('');

        const uploadPromises: Promise<any>[] = [];
        zipFiles.forEach(file => {
            const reader = new FileReader();
            const promise = new Promise((resolve, reject) => {
                reader.onload = async (event) => {
                    if (!event.target?.result) return;
                    const zip = await JSZip.loadAsync(event.target.result);
                    for (const fileName of Object.keys(zip.files)) {
                        if (fileName.endsWith('.json')) {
                            const fileContent = await zip.files[fileName].async('string');
                            if (isFormattedCorrectly(fileContent)) postJsonToServer(fileContent, resolve, reject);
                        }
                    }
                }
                reader.readAsArrayBuffer(file);
            });
            uploadPromises.push(promise);
        });
        jsonFiles.forEach(file => {
            const reader = new FileReader();
            const promise = new Promise((resolve, reject) => {
                reader.onload = (event) => {
                    postJsonToServer(String(event.target!.result), resolve, reject);
                }
                reader.readAsText(file);
            });
            uploadPromises.push(promise);
        })

        // Wait for all promises to resolve
        Promise.all(uploadPromises).then(() => {
            setZipFiles([]);
            setJsonFiles([]);
            setLoadingMessage('Success! You will be able to view your updated stats within an hour.');
        }).catch(error => {
            console.error(error);
            setLoadingMessage('');
            setErrorMessage('There seems to be a problem with the file(s) you uploaded. Make sure you have the correct file(s) and try again.');
        });
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'text/json': ['.json'],
            'application/zip': ['.zip'],
            'application/x-zip-compressed': ['.zip'],
        },
        maxSize: maxFileSize,
    });

    return (
        <div className={'dropzone-all'}>
            <div className={'dropzone-info'}>
                Upload your extended streaming history here.
                <span className={'custom-link whats-this'} onClick={() => setPopupVisible(true)}> <u>What's this?</u></span>
            </div>
            <div {...getRootProps({
                className: 'dropzone'
            })}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Drop the file here ...</p>
                ) : (
                    <p>Drag and drop .zip file here, or click to select file</p>
                )}
            </div>
            {loadingMessage !== '' && <div className={'loading-acct'}>{loadingMessage}</div>}
            {errorMessage !== '' && <p className={'dropzone-error'}>{errorMessage}</p>}
            <ul>
                {(zipFiles.concat(jsonFiles)).map((file, index) => (
                    <li key={file.name} style={{position: 'relative'}}>
                        <div
                            className={'dropzone-item'}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(-1)}
                        >
                            {file.name}
                        </div>
                        {hoveredIndex === index && <div className={'dropzone-item-remove'} onClick={() => removeItem(file)} onMouseEnter={() => setHoveredIndex(index)}></div>}
                    </li>
                ))}
            </ul>
            {(zipFiles.length + jsonFiles.length) !== 0 && (
                <div className={'dropdown-submit-wrapper'}>
                    <div className={'login-button dropzone-submit'} onClick={submit}><b>SUBMIT</b></div>
                </div>
            )}
            {popupVisible && (
                <ExtendedStreamingInfo callback={() => setPopupVisible(false)} />
            )}
        </div>
    );
}

const unixMillisToString = (unixMillis: string) => {
    const date = new Date(+unixMillis);
    return date.toLocaleDateString();
}

const truncateStr = (str: string) => {
    if (!str) return null;
    let num = 25;
    if (str.length <= num) return str;
    return str.slice(0, num) + '...';
}

const isFormattedCorrectly = (file: string) => {
    file = JSON.parse(file);
    if (!Array.isArray(file)) {
        return false;
    }

    for (let i = 0; i < file.length; i++) {
        const item = file[i];

        if (
            typeof item !== 'object' ||
            !item.hasOwnProperty('ts') ||
            !item.hasOwnProperty('username') ||
            !item.hasOwnProperty('platform') ||
            !item.hasOwnProperty('ms_played') ||
            !item.hasOwnProperty('conn_country') ||
            !item.hasOwnProperty('ip_addr_decrypted') ||
            !item.hasOwnProperty('user_agent_decrypted') ||
            !item.hasOwnProperty('master_metadata_track_name') ||
            !item.hasOwnProperty('master_metadata_album_artist_name') ||
            !item.hasOwnProperty('master_metadata_album_album_name') ||
            !item.hasOwnProperty('spotify_track_uri') ||
            !item.hasOwnProperty('episode_name') ||
            !item.hasOwnProperty('episode_show_name') ||
            !item.hasOwnProperty('spotify_episode_uri') ||
            !item.hasOwnProperty('reason_start') ||
            !item.hasOwnProperty('reason_end') ||
            !item.hasOwnProperty('shuffle') ||
            !item.hasOwnProperty('skipped') ||
            !item.hasOwnProperty('offline') ||
            !item.hasOwnProperty('offline_timestamp') ||
            !item.hasOwnProperty('incognito_mode')
        ) {
            return false;
        }
    }

    return true;
}
