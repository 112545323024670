import {
    needsLogin,
    PrimaryInfo, spotifyAuth,
} from "../util/util";
import React, {useEffect} from "react";
//import {SpotifyStats} from "./spotify-stats";
import {MMStats} from "./mm-stats";

export const Stats = () => {
    //const selectedStyle = 'selector-selected';
    //const unselectedStyle = 'selector-unselected';
    //const [spotifyStyle, setSpotifyStyle] = useState(selectedStyle);
    //const [mmStyle, setMmStyle] = useState(unselectedStyle);
    //const [currentData, setCurrentData] = useState(<MMStats />);

    useEffect(() => {
        sessionStorage.setItem('route', 'stats');
        if (needsLogin()) spotifyAuth();
    }, []);

    /*const setToSpotify = () => {
        setSpotifyStyle(selectedStyle);
        setMmStyle(unselectedStyle);

        setCurrentData(<SpotifyStats />);
    }
    const setToMM = () => {
        setSpotifyStyle(unselectedStyle);
        setMmStyle(selectedStyle);

        setCurrentData(<MMStats />);
    }*/

    return (
        <>
            <PrimaryInfo text={'Stats central.'}/>
            {/*<div className={'selector'}>
                <div className={spotifyStyle + ' selector-option corner-rounded-left'} onClick={setToSpotify}>Spotify</div>
                <div className={mmStyle + ' selector-option corner-rounded-right'} onClick={setToMM}>Music Metrics</div>
            </div>*/}
            {/*{currentData}*/}
            <MMStats />
        </>
    );
}

export const addCommaToNumber = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}