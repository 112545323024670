import React, {useEffect, useState} from "react";
import {
    albumCountProps,
    albumTimeProps,
    artistCountProps,
    artistTimeProps, TopTableStyleProps,
    trackCountProps,
    trackTimeProps
} from "./top-table-props";
import {TableType, TopItem} from "./types";

const defaultProps = trackCountProps;

interface TopTableProps {
    items: TopItem[],
    type: TableType,
}

interface DropdownProps {
    values: number[],
    initial: number,
}

export const TopTable = (props: TopTableProps) => {
    const [currentHeader, setCurrentHeader] = useState<React.ReactElement>();
    const [currentRow, setCurrentRow] = useState<((item: TopItem) => React.ReactElement)>();
    const [displayedItems, setDisplayedItems] = useState<TopItem[]>([]);
    const [dropdown, setDropdown] = useState<React.ReactElement>();
    const allItems = props.items;

    useEffect(() => {
        const Dropdown = (props: DropdownProps) => {
            const ddValues = props.values;
            const [isOpen, setIsOpen] = useState(false);
            const [currentValue, setCurrentValue] = useState(props.initial);

            // Close the dropdown if the user clicks outside of it
            useEffect(() => {
                document.addEventListener('click', (event) => {
                    const target = event.target as HTMLElement;
                    if (!target.classList.toString().includes('dropdown-stats')) {
                        setIsOpen(false);
                    }
                });
            }, []);

            const toggle = () => {
                setIsOpen(!isOpen);
            }

            const itemClicked = (size: number) => {
                toggle();
                setCurrentValue(size);
                setDisplayedItems(allItems.slice(0, size));
            }

            return (
                <div className={'dd-wrapper'}>
                    <div className='dropdown-stats'>
                        {isOpen && (
                            <div className='dropdown-stats-menu'>
                                <ul>
                                    <li onClick={() => itemClicked(ddValues[0])}>{ddValues[0]}</li>
                                    <li onClick={() => itemClicked(ddValues[1])}>{ddValues[1]}</li>
                                    <li onClick={() => itemClicked(ddValues[2])}>{ddValues[2]}</li>
                                    <li onClick={() => itemClicked(ddValues[3])}>{ddValues[3]}</li>
                                </ul>
                            </div>
                        )}
                        <div className='dropdown-stats-button' onClick={toggle}>
                            Select table size... {currentValue}
                        </div>
                    </div>
                </div>
            );
        }

        let currentProps: TopTableStyleProps = defaultProps;

        switch (props.type) {
            case TableType.TrackCount:
                currentProps = trackCountProps;
                break;
            case TableType.TrackTime:
                currentProps = trackTimeProps;
                break;
            case TableType.ArtistCount:
                currentProps = artistCountProps;
                break;
            case TableType.ArtistTime:
                currentProps = artistTimeProps;
                break;
            case TableType.AlbumCount:
                currentProps = albumCountProps;
                break;
            case TableType.AlbumTime:
                currentProps = albumTimeProps;
                break;
            default:
                currentProps = defaultProps;
        }
        setDisplayedItems(props.items.slice(0, currentProps.defaultCount));
        setDropdown(<Dropdown values={currentProps.ddValues} initial={currentProps.defaultCount} />);

        const mediaQuery = window.matchMedia('(orientation: portrait)');
        setCurrentHeader(mediaQuery.matches ? currentProps.head_vert : currentProps.head_horiz);
        setCurrentRow(mediaQuery.matches ? () => currentProps.row_vert : () => currentProps.row_horiz);

        const handleOrientationChange = (event: MediaQueryListEvent) => {
            setCurrentHeader(event.matches ? currentProps.head_vert : currentProps.head_horiz);
            setCurrentRow(event.matches ? () => currentProps.row_vert : () => currentProps.row_horiz);
        }

        mediaQuery.addEventListener('change', handleOrientationChange);
        return () => {
            mediaQuery.removeEventListener('change', handleOrientationChange);
        }
    }, [props, allItems]);

    return (
        <div>
            <table className={'table-all'}>
                {currentHeader}
                <tbody>
                    {(displayedItems.length !== 0) && displayedItems.map(currentRow!)}
                </tbody>
            </table>
            {dropdown}
        </div>
    );
}