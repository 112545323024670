export enum TableType {
    TrackCount,
    TrackTime,
    ArtistCount,
    ArtistTime,
    AlbumCount,
    AlbumTime,
}

export interface TopItem {
    rank: number,
    count: number,
    image: string,
    artistId: string,
    artist: string,
    albumId: string,
    album: string,
    trackId: string,
    track: string,
}

export interface DecadeBreakdown {
    decade: string,
    count: number,
}

export interface AveragePopularity {
    artistId: string,
    artist: string,
    trackId: string,
    track: string,
    popularity: number,
}

export interface ModeYear {
    year: number,
    count: number,
}