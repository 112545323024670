import './stats.css';
import {ExtendedStreamingInfo, InfoProps} from "../util/util";
import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import {TopTable} from "./top-table";
import {AllCharts} from "./charts";
import {addCommaToNumber} from "./stats";
import {getStats} from "./actions";
import {DecadeBreakdown, ModeYear, TableType, TopItem} from "./types";

const DEFAULT_TIME_RANGES = ['All time', 'Last 7 days', 'Last 30 days', 'This year so far', 'Custom range...'];

const DEFAULT_START_TIME = 0;
const DEFAULT_END_TIME = Date.now();

export const MMStats = () => {
    // STYLING VARIABLES
    const selectedStyle = 'selector-selected';
    const unselectedStyle = 'selector-unselected';
    const [trackStyle, setTrackStyle] = useState(selectedStyle);
    const [artistStyle, setArtistStyle] = useState(unselectedStyle);
    const [albumStyle, setAlbumStyle] = useState(unselectedStyle);
    const [chartStyle, setChartStyle] = useState(unselectedStyle);
    const [showSelector2, setShowSelector2] = useState(true);
    const [countStyle, setCountStyle] = useState(selectedStyle);
    const [timeStyle, setTimeStyle] = useState(unselectedStyle);
    const [showDataSelectors, setShowDataSelectors] = useState(false);
    const [showTimeSelector, setShowTimeSelector] = useState(false);

    // TIME VARIABLES
    const [displayedTimeRange, setDisplayedTimeRange] = useState('All time');
    const [usingCustomTimeRange, setUsingCustomTimeRange] = useState(false);
    const [startTime, setStartTime] = useState(DEFAULT_START_TIME);
    const [endTime, setEndTime] = useState(DEFAULT_END_TIME);
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());

    // DATA VARIABLES (only fetched once, when the Stats component loads)
    const [averageLength, setAverageLength] = useState<string>('0');
    const [averagePopularity, setAveragePopularity] = useState([]);
    const [averageYear, setAverageYear] = useState(0);
    const [decadeBreakdown, setDecadeBreakdown] = useState<string[][]>([]);
    //const [firstTrack, setFirstTrack] = useState({});
    const [hourBreakdown, setHourBreakdown] = useState([]);
    const [medianYear, setMedianYear] = useState(0);
    const [modeYear, setModeYear] = useState<ModeYear[]>([]);
    const [percentExplicit, setPercentExplicit] = useState<string>('0');
    const [topAlbums, setTopAlbums] = useState([]);
    const [topAlbumsTime, setTopAlbumsTime] = useState([]);
    const [topArtists, setTopArtists] = useState([]);
    const [topArtistsTime, setTopArtistsTime] = useState([]);
    const [topTracks, setTopTracks] = useState([]);
    const [topTracksTime, setTopTracksTime] = useState([]);
    const [totalMinutes, setTotalMinutes] = useState<string>('0');
    const [totalTracks, setTotalTracks] = useState<string>('0');
    const [uniqueAlbums, setUniqueAlbums] = useState<string>('0');
    const [uniqueArtists, setUniqueArtists] = useState<string>('0');
    const [uniqueTracks, setUniqueTracks] = useState<string>('0');
    const [weekDayBreakdown, setWeekDayBreakdown] = useState<string[][]>([]);

    // OTHER
    const [currentData, setCurrentData] = useState(<StatsInfo text={'Loading...'}/>);
    const [popupVisible, setPopupVisible] = useState(true);
    const [useUserAgnosticStats, setUseUserAgnosticStats] = useState(false);

    useEffect(() => {
        getStats(startTime, endTime, useUserAgnosticStats).then(data => {
            if (data === 'No tracks found for this time period.') {
                setShowTimeSelector(true);
                setShowDataSelectors(false);
                setCurrentData(<StatsInfo text={'No listening history found for this time period.'}/>);
                return;
            }

            // ADD RANK COLUMN FOR RELEVANT ARRAYS
            addRankColumn(data.topAlbums.items);
            addRankColumn(data.topAlbumsTime.items);
            addRankColumn(data.topArtists.items);
            addRankColumn(data.topArtistsTime.items);
            addRankColumn(data.topTracks.items);
            addRankColumn(data.topTracksTime.items);

            // DO CALCULATIONS FOR OTHER RELEVANT DATA
            let minutes = Math.floor(data.averageLength.value / 60);

            // ASSIGN DATA TO RESPECTIVE STATES
            setAverageLength(minutes + ':' + makeIntDoubleDigit(data.averageLength.value - minutes * 60));
            setAveragePopularity(data.averagePopularity.items);
            setAverageYear(data.averageYear.value);
            setDecadeBreakdown(convertDecadesToPieChartData(data.decadeBreakdown.items));
            //setFirstTrack(data.firstTrack);
            setHourBreakdown(data.hourBreakdown.items);
            setMedianYear(data.medianYear.value);
            setModeYear(data.modeYear.items);
            setPercentExplicit(data.percentExplicit.value + '%');
            setTopAlbums(data.topAlbums.items);
            setTopAlbumsTime(data.topAlbumsTime.items);
            setTopArtists(data.topArtists.items);
            setTopArtistsTime(data.topArtistsTime.items);
            setTopTracks(data.topTracks.items);
            setTopTracksTime(data.topTracksTime.items);
            setTotalMinutes(addCommaToNumber(data.totalMinutes.value));
            setTotalTracks(addCommaToNumber(data.totalTracks.value));
            setUniqueAlbums(addCommaToNumber(data.uniqueAlbums.value));
            setUniqueArtists(addCommaToNumber(data.uniqueArtists.value));
            setUniqueTracks(addCommaToNumber(data.uniqueTracks.value));
            setWeekDayBreakdown(addWeekDayToData(data.weekDayBreakdown.items));

            // REMOVE LOADING MESSAGE
            setShowTimeSelector(true);
            setShowDataSelectors(true);
            setCurrentData(<TopTable items={data.topTracks.items} type={TableType.TrackCount}/>);

            // RESET THE SELECTORS
            setTrackStyle(selectedStyle);
            setArtistStyle(unselectedStyle);
            setAlbumStyle(unselectedStyle);
            setChartStyle(unselectedStyle);
            setShowSelector2(true);
            setCountStyle(selectedStyle);
            setTimeStyle(unselectedStyle);
            setCountStyle(selectedStyle);
            setTimeStyle(unselectedStyle);
        })
    }, [startTime, endTime, useUserAgnosticStats]);

    const setToTrack = () => {
        setTrackStyle(selectedStyle);
        setArtistStyle(unselectedStyle);
        setAlbumStyle(unselectedStyle);
        setChartStyle(unselectedStyle);
        setShowSelector2(true);
        setCountStyle(selectedStyle);
        setTimeStyle(unselectedStyle);

        setCurrentData(<TopTable items={topTracks} type={TableType.TrackCount}/>);
    }
    const setToArtist = () => {
        setTrackStyle(unselectedStyle);
        setArtistStyle(selectedStyle);
        setAlbumStyle(unselectedStyle);
        setChartStyle(unselectedStyle);
        setShowSelector2(true);
        setCountStyle(selectedStyle);
        setTimeStyle(unselectedStyle);

        setCurrentData(<TopTable items={topArtists} type={TableType.ArtistCount}/>);
    }
    const setToAlbum = () => {
        setTrackStyle(unselectedStyle);
        setArtistStyle(unselectedStyle);
        setAlbumStyle(selectedStyle);
        setChartStyle(unselectedStyle);
        setShowSelector2(true);
        setCountStyle(selectedStyle);
        setTimeStyle(unselectedStyle);

        setCurrentData(<TopTable items={topAlbums} type={TableType.AlbumCount}/>);
    }
    const setToChart = () => {
        setTrackStyle(unselectedStyle);
        setArtistStyle(unselectedStyle);
        setAlbumStyle(unselectedStyle);
        setChartStyle(selectedStyle);
        setShowSelector2(false);

        setCurrentData(<AllCharts
            averageLength={averageLength}
            averagePopularity={averagePopularity}
            averageYear={averageYear}
            decadeBreakdown={decadeBreakdown}
            hourBreakdown={hourBreakdown}
            medianYear={medianYear}
            modeYear={modeYear}
            percentExplicit={percentExplicit}
            totalMinutes={totalMinutes}
            totalTracks={totalTracks}
            uniqueAlbums={uniqueAlbums}
            uniqueArtists={uniqueArtists}
            uniqueTracks={uniqueTracks}
            weekDayBreakdown={weekDayBreakdown}
        />);
    }
    const setToCount = () => {
        setCountStyle(selectedStyle);
        setTimeStyle(unselectedStyle);

        if (trackStyle === selectedStyle) {
            setCurrentData(<TopTable items={topTracks} type={TableType.TrackCount}/>);
        } else if (artistStyle === selectedStyle) {
            setCurrentData(<TopTable items={topArtists} type={TableType.ArtistCount}/>);
        } else if (albumStyle === selectedStyle) {
            setCurrentData(<TopTable items={topAlbums} type={TableType.AlbumCount}/>);
        }
    }
    const setToTime = () => {
        setCountStyle(unselectedStyle);
        setTimeStyle(selectedStyle);

        if (trackStyle === selectedStyle) {
            setCurrentData(<TopTable items={topTracksTime} type={TableType.TrackTime}/>);
        } else if (artistStyle === selectedStyle) {
            setCurrentData(<TopTable items={topArtistsTime} type={TableType.ArtistTime}/>);
        } else if (albumStyle === selectedStyle) {
            setCurrentData(<TopTable items={topAlbumsTime} type={TableType.AlbumTime}/>);
        }
    }

    const toggleUserAgnosticStats = () => {
        setUseUserAgnosticStats(!useUserAgnosticStats);
        setShowTimeSelector(false);
        setShowDataSelectors(false);
        setCurrentData(<StatsInfo text={'Loading...'}/>);
        // triggers useEffect
    }

    const submitTimes = (potStartTime: number, potEndTime: number) => {
        if (validateTimes(potStartTime, potEndTime)) {
            if (potStartTime === startTime && potEndTime === endTime) return;
            setStartTime(potStartTime);
            setEndTime(potEndTime);
            setShowTimeSelector(false);
            setShowDataSelectors(false);
            setCurrentData(<StatsInfo text={'Loading...'}/>);
        } else {
            setShowTimeSelector(true);
            setShowDataSelectors(false);
            setCurrentData(<StatsInfo text={'Invalid time range, try again.'}/>);
        }
        // useEffect triggered when startTime / endTime change
    }

    const Dropdown = () => {
        const [isOpen, setIsOpen] = useState(false);

        // Close the dropdown if the user clicks outside of it
        useEffect(() => {
            document.addEventListener('click', (event) => {
                const target = event.target as HTMLElement;
                if (!target.classList.toString().includes('dropdown-time')) setIsOpen(false);
            });
        }, []);

        const toggle = () => {
            setIsOpen(!isOpen);
        }

        const itemClicked = (startTime: number, endTime: number, index: number) => {
            // Assumes that custom time range is the last item in the array
            if (index !== DEFAULT_TIME_RANGES.length - 1) {
                submitTimes(startTime, endTime);
                setUsingCustomTimeRange(false);
            } else {
                setUsingCustomTimeRange(true);
            }
            toggle();
            setDisplayedTimeRange(DEFAULT_TIME_RANGES[index]);
        }

        return (
            <div className={'dd-wrapper'}>
                <div className='dropdown-time'>
                    {isOpen && (
                        <div className='dropdown-time-menu'>
                            <ul>
                                <li onClick={() => {
                                    itemClicked(0, Date.now(), 0);
                                }}>{DEFAULT_TIME_RANGES[0]}</li>
                                <li onClick={() => {
                                    const now = Date.now();
                                    itemClicked(now - (7 * 24 * 60 * 60 * 1000), now, 1);
                                }}>{DEFAULT_TIME_RANGES[1]}</li>
                                <li onClick={() => {
                                    const now = Date.now();
                                    itemClicked(now - (30 * 24 * 60 * 60 * 1000), now, 2);
                                }}>{DEFAULT_TIME_RANGES[2]}</li>
                                <li onClick={() => {
                                    const now = new Date();
                                    const yearEpoch = new Date(now.getFullYear(), 0, 1);
                                    itemClicked(yearEpoch.getTime(), Date.now(), 3);
                                }}>{DEFAULT_TIME_RANGES[3]}</li>
                                <li onClick={() => {
                                    itemClicked(0, Date.now(), 4);
                                }}>{DEFAULT_TIME_RANGES[4]}</li>
                            </ul>
                        </div>
                    )}
                    <div className={'dropdown-time-button'} onClick={toggle}>
                        {displayedTimeRange}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            {showTimeSelector && (
                <>
                    <div className={'small-description'}>Showing stats from:</div>
                    <div className={'time-dd-username'}>
                        <div style={{width: '10rem'}}></div>
                        <div style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <Dropdown/>
                            {usingCustomTimeRange && (
                                <div className={'custom-time-wrapper'}>
                                    <div className={'custom-time'}>
                                        <div className={'time-input-wrapper'}>
                                            <DatePicker className={'time-input'} selected={selectedStartDate}
                                                        onChange={(date: Date) => {
                                                            setSelectedStartDate(date)
                                                        }}/>
                                        </div>
                                        <div>to</div>
                                        <div className={'time-input-wrapper'}>
                                            <DatePicker className={'time-input'} selected={selectedEndDate}
                                                        onChange={(date: Date) => {
                                                            setSelectedEndDate(date)
                                                        }}/>
                                        </div>
                                        <div className={'submit-times'}
                                             onClick={() => submitTimes(dateToMillis(selectedStartDate), dateToMillis(selectedEndDate) + 86399999)}>
                                            <b>GO</b></div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={'user-agnostic-switch'} onClick={toggleUserAgnosticStats}>
                            Switch to {useUserAgnosticStats ? 'just you' : 'all users'}
                        </div>
                    </div>
                </>
            )}
            {showDataSelectors && (
                <>
                    <div className={'selector'}>
                        <div className={trackStyle + ' selector-option corner-rounded-left'} onClick={setToTrack}>Top
                            Tracks
                        </div>
                        <div className={artistStyle + ' selector-option'} onClick={setToArtist}>Top Artists</div>
                        <div className={albumStyle + ' selector-option'} onClick={setToAlbum}>Top Albums</div>
                        <div className={chartStyle + ' selector-option corner-rounded-right'}
                             onClick={setToChart}>Other
                        </div>
                    </div>
                    {showSelector2 && (
                        <div className={'selector extra-bottom-margin'}>
                            <div className={countStyle + ' selector-option corner-rounded-left'} onClick={setToCount}>By
                                Count
                            </div>
                            <div className={timeStyle + ' selector-option corner-rounded-right'} onClick={setToTime}>By
                                Time
                            </div>
                        </div>
                    )}
                </>
            )}
            {currentData}
            {popupVisible && (
                <ExtendedStreamingInfo callback={() => setPopupVisible(false)}/>
            )}
        </div>
    );
}

// SECONDARY COMPONENTS
const StatsInfo = (props: InfoProps) => {
    return (
        <div className={'default-text-color loading'}>{props.text}</div>
    );
}

// HELPER FUNCTIONS
const addRankColumn = (items: TopItem[]) => {
    let rank = 1;
    items.forEach(item => {
        item.rank = rank;
        rank++;
    });
}

const convertDecadesToPieChartData = (data: DecadeBreakdown[]) => {
    let result = [];
    result.push(['Decade', 'Count']);
    data.forEach(item => {
        result.push([item.decade, item.count]);
    });
    return result;
}

const makeIntDoubleDigit = (num: number) => {
    if (num < 10) return '0' + num;
    return num;
}

const validateTimes = (startTime: number, endTime: number) => {
    if (isNaN(startTime) || isNaN(endTime)) return false;
    if (startTime < 0) return false;
    if (endTime < startTime) return false;
    if (endTime < 1145746800000) return false; // The day Spotify was released
    return true;
}

const dateToMillis = (date: Date) => {
    const beginningOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    return beginningOfDay.getTime();
}

const addWeekDayToData = (data: number[]): any[] => {
    // assumes data is formatted correctly
    let result = [];
    result.push(['Day', 'Count']);
    result.push(['Sunday', data[0]]);
    result.push(['Monday', data[1]]);
    result.push(['Tuesday', data[2]]);
    result.push(['Wednesday', data[3]]);
    result.push(['Thursday', data[4]]);
    result.push(['Friday', data[5]]);
    result.push(['Saturday', data[6]]);
    return result;
}
